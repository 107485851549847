import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from "@angular/core";
import * as SummaryActions from './summary.actions';
import { mergeMap, map, catchError, of } from 'rxjs';
import { SummaryService, GetSummaryAllScopesRequest } from '@ekowitryna/ekowitryna-esg-calculator-angular-api';
import { Store } from '@ngrx/store';
import { selectSelectedCompany } from '../company/company-context.selectors';

@Injectable()
export class SummaryEffects {
  loadSummary$ = createEffect(() => this.actions$.pipe(
    ofType(SummaryActions.loadSummary),
    mergeMap(action => {
      const request: GetSummaryAllScopesRequest = {
        companyId: action.companyId,
        companyUnitIds: action.companyUnitIds,
        year: action.year,
        co2ScoreMassUnit: 'Mg',
      }

      return this.summaryService.getAllScopes(request).pipe(
        map(res => SummaryActions.loadSummarySuccess({ summary: res })),
        catchError(error => of(SummaryActions.loadSummaryFailure({ error })))
      );
    }
    )
  ));

  constructor(
    private actions$: Actions,
    private summaryService: SummaryService,
    private store: Store
  ) {}
}
