import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last, lastValueFrom } from 'rxjs';
import { Company } from './models/company.model';
import { environment } from '../../../../environments/environment';
import { BdoServicePage } from './models/bdo-service-page.model';
import { CompanyUnit } from './models/company-unit.model';


const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getCompanies(): Promise<BdoServicePage<Company>> {
    const source =  this.http.get<BdoServicePage<Company>>(BASE_URL + 'accounts/company/list',
    );

    return lastValueFrom(source);
  }
  
  getCompany(companyId: string): Promise<Company> {
    const source =  this.http.get<Company>(BASE_URL + `accounts/company`, 
      { params: { companyId: companyId } }
    );

    return lastValueFrom(source);
  }

  getCompanyUnits(companyId: string, hideInactive: boolean, page: number, pageSize: number, query?: string): Promise<BdoServicePage<CompanyUnit>> {
    const source = this.http.get<BdoServicePage<CompanyUnit>>(BASE_URL + 'accounts/company/unit/list',
      { params: {companyId: companyId, page: page.toString(), size: pageSize.toString(), query: '', hideInactive: hideInactive} }
    );

    return lastValueFrom(source);
  }
}
