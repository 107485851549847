import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HubComponent } from './shared/templates/hub/hub.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: HubComponent,
    children: [
      { path: '', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
      { path: 'scope1', loadChildren: () => import('./features/scope1/scope1.module').then(m => m.Scope1Module), canActivate: [AuthGuard]},
      { path: 'scope2', loadChildren: () => import('./features/scope2/scope2.module').then(m => m.Scope2Module), canActivate: [AuthGuard]},
      { path: 'scope3', loadChildren: () => import('./features/scope3/scope3.module').then(m => m.Scope3Module), canActivate: [AuthGuard] },
    ]
  },
  { path: 'conference', loadChildren: () => import('./features/conference-form/conference-form.module').then(m => m.ConferenceFormModule)  }, 
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
