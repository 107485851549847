import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadUser } from './user.actions';
import { Injectable } from "@angular/core";
import * as UserActions from './user.actions';
import { KeycloakService } from 'keycloak-angular';
import { mergeMap, map, catchError, of } from 'rxjs';
import { User } from './user.model';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUser),
    mergeMap(() => this.keycloakService.loadUserProfile().then(profile => {
      
      const user = new User(
        profile,
        this.keycloakService.getUserRoles(),
        this.keycloakService.getKeycloakInstance().tokenParsed
      )
      return UserActions.loadUserSuccess({ user });
    }).catch(error => {
      return UserActions.loadUserFailure({ error });
    }))
  ));

  constructor(
    private actions$: Actions,
    private keycloakService: KeycloakService
  ) {}
}
