import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import packageInfo from '../package.json';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: "https://d14086d7c65c940a3279a6563b546edf@o392031.ingest.us.sentry.io/4508169058582528",
    environment: environment.realm,
    release: packageInfo.version,
    tracePropagationTargets: ['localhost', 'esg.ekowitryna.pl'],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // Sample rate at 10%. Adjust as needed for production.
    replaysOnErrorSampleRate: 1.0, // Set sample rate to 100% for sessions with errors.
  });
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true
})
  .catch(err => console.error(err));
