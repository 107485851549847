import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';


import {
  loadCompanies,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  loadCompanyUnits,
  loadCompanyUnitsSuccess,
  loadCompanyUnitsFailure,
  changeSelectedCompany,
  restoreSelectedCompany,
} from './company-context.actions';
import { CompanyService } from '../../services/companies/company.service';
import { Store } from '@ngrx/store';
import { selectSelectedCompany } from './company-context.selectors';

@Injectable()
export class CompanyContextEffects {  
  // Effect to load companies based on pagination and search term
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanies),
      exhaustMap(() =>
        from(this.companyService.getCompanies()).pipe(
          map(res => loadCompaniesSuccess({items: res.content})),
          catchError(error => of(loadCompaniesFailure({ error: error.message })))
        ))
    )
  );

  selectInitialCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompaniesSuccess),
      withLatestFrom(this.store.select(selectSelectedCompany)),
      map(([{ items }, restoredCompany] ) => {
        if (items.length > 0) {

          const restoredCompanyExists = items.some(c => c.id === restoredCompany?.id);

          if(restoredCompany && restoredCompanyExists) {
            return restoreSelectedCompany({ item: restoredCompany });
          }
          
          return changeSelectedCompany({ item: items[0] });
        }
        return loadCompanyUnitsFailure({ error: 'No companies found to select.' });
      })
    )
  );

  loadCompanyUnitsWhenCompanySelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedCompany),
      exhaustMap(({ item }) =>
        from(this.companyService.getCompanyUnits(item.id, false, 0, 10000, '')).pipe(
          map(page => loadCompanyUnitsSuccess({ page })),
          catchError(error => of(loadCompanyUnitsFailure({ error: error.message })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private store: Store
  ) { }
}
