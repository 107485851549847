import { NgModule, APP_INITIALIZER, isDevMode, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ApiModule, Configuration, ConfigurationParameters } from "@ekowitryna/ekowitryna-esg-calculator-angular-api";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateInterceptor } from './shared/interceptors/date-interceptor';
import { LanguageInterceptor } from './shared/interceptors/language-interceptor';
import { SharedModule } from './shared/shared.module';
import { companyContextReducer, localStorageMetaReducer } from './core/state/company/company-context.reducer';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CompanyContextEffects } from './core/state/company/company-context.effects';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { MessagesModule } from 'primeng/messages';
import { AppPanelComponent } from "./shared/components/app-panel/app-panel.component";
import { ConferenceFormModule } from "./features/conference-form/conference-form.module";
import { SummaryEffects } from './core/state/summary/summary.effects';
import { UserEffects } from './core/state/user/user.effects';
import { summaryReducer } from './core/state/summary/summary.reducer';
import { version } from '../../package.json';
import { filter, map } from 'rxjs/operators';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as Sentry from '@sentry/angular';
import { ConferenceFormComponent } from './features/conference-form/conference-form.component';

import { QRCodeModule } from 'angularx-qrcode';
import { userReducer } from './core/state/user/user.reducer';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + version);
}


export function initializeKeycloak(keycloak: KeycloakService, router: Router, activatedRoute: ActivatedRoute) {
  return async () => {
    await keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.realm,
        clientId: environment.clientId
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false
      },
      shouldAddToken: (request) => {
        return !request.url.includes('/form') && !request.url.includes('static1.ekowitryna.pl');
      }
    });

    const isLoggedIn = await keycloak.isLoggedIn();


    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      map(route => route.component)
    ).subscribe(async (component) => {

      if (!isLoggedIn && component !== ConferenceFormComponent) {
        await keycloak.login();  
      }
    });
  };
}



@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppPanelComponent,
        ConfirmDialogModule,
        ToastModule,
        MessagesModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        FormsModule,
        ReactiveFormsModule,
        KeycloakAngularModule,
        ConferenceFormModule,
        SharedModule,
        SidebarModule,
        TooltipModule,
        ButtonModule,
        ChipModule,
        ZXingScannerModule,
        ConfirmPopupModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot({
            companyContext: companyContextReducer,
            summary: summaryReducer,
            user: userReducer
        }, {
            metaReducers: [
                localStorageMetaReducer,
            ]
        }),
        ApiModule.forRoot(esgApiConfigFactory),
        EffectsModule.forFeature([CompanyContextEffects, SummaryEffects, UserEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
        GoogleTagManagerModule,
        QRCodeModule], providers: [
        MessageService,
        ConfirmationService,
        provideAnimationsAsync(),
        { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
        { provide: 'googleTagManagerPreview', useValue: environment.googleTagManagerPreview },
        { provide: 'googleTagManagerAuth', useValue: environment.googleTagManagerAuth },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            deps: [KeycloakService, Router],
            multi: true
        },
        KeycloakService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

export function esgApiConfigFactory (): Configuration {
  const params: ConfigurationParameters = {
    basePath: `${environment.apiUrl}esg`,
  };
  return new Configuration(params);
}
