import { KeycloakProfile, KeycloakTokenParsed } from "keycloak-js";

export class User {
    constructor(
        public readonly profile: KeycloakProfile,
        public readonly roles: string[],
        public readonly token: KeycloakTokenParsed | undefined
    ){}

    hasRealmRoles(role: string): boolean {

        this.token?.realm_access?.roles?.forEach((realmRole) => {
            if (realmRole === role) {
                return true;
            }

            return false;
        });

        return false;
    }

    hasClientRole(client: string, role: string): boolean {
        const roles = this.token?.resource_access?.[client]?.roles;
        if (roles) {
            for (const clientRole of roles) {
                if (clientRole === role) {
                    return true;
                }
            }
        }

        return false;
    }
}