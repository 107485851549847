import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ThemeService } from './shared/services/theme-service';
import { DOCUMENT } from '@angular/common';
import { PrimengTranslationService } from '../app/shared/services/primeng-translation-service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(
    private translate: TranslateService,
    public themeService: ThemeService,
    public primengTranslationService: PrimengTranslationService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.initLang();
    this.loadTheme();
    themeService.afterRefresh();
  }


  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  initLang() {
    const savedLang = localStorage.getItem('appLang');
    const browserLang = this.translate.getBrowserLang();
    const supportedLangs = ['pl', 'en', 'de','es','fr'];
    
    let defaultLang = 'pl'; 

    if (savedLang && supportedLangs.includes(savedLang)) {
      defaultLang = savedLang;
    } else if (browserLang && supportedLangs.includes(browserLang)) {
      defaultLang = browserLang;
    }

    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);
    this.primengTranslationService.setTranslations();
  }

  loadTheme() {
    // Determine the theme based on user preference or default
    const theme = 'light'; // or 'dark', or from user settings

    // Create a new link element for the theme
    const themeLink = this.document.createElement('link');
    themeLink.id = 'app-theme';
    themeLink.rel = 'stylesheet';
    themeLink.href = `assets/themes/material/${theme}/theme.css`;

    // Append the new theme link at the end of the <head>
    this.document.head.appendChild(themeLink);
  }
}
