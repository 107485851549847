import { ActionReducer, INIT, UPDATE, createReducer, on } from '@ngrx/store';
import {
  loadCompanies,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  loadCompanyUnits,
  loadCompanyUnitsSuccess,
  loadCompanyUnitsFailure,
  changeSelectedCompany,
  changeYear,
} from './company-context.actions';
import { Company } from '../../services/companies/models/company.model';
import { BdoServicePage } from '../../services/companies/models/bdo-service-page.model';
import { CompanyUnit } from '../../services/companies/models/company-unit.model';



export interface CompanyContextState {
  companies: Company[] | null;
  companyUnits: BdoServicePage<CompanyUnit> | null;
  selectedCompany: Company | null;
  loadingCompanies: boolean;
  loadingCompanyUnits: boolean;
  year: number;
  error: string | null;
}

export const initialState: CompanyContextState = {
  companies: null,
  companyUnits: null,
  selectedCompany: null,
  loadingCompanies: true,
  loadingCompanyUnits: true,
  year: new Date().getFullYear(),
  error: null
};

export const companyContextReducer = createReducer(
  initialState,
  // Handle loading companies
  on(loadCompanies, (state) => ({
    ...state,
    loadingCompanies: true,
    error: null
  })),
  on(loadCompaniesSuccess, (state, { items }) => ({
    ...state,
    loadingCompanies: false,
    companies: items
  })),
  on(loadCompaniesFailure, (state, { error }) => ({
    ...state,
    loadingCompanies: false,
    error
  })),

  // Handle loading company units
  on(loadCompanyUnits, (state) => ({
    ...state,
    loadingCompanyUnits: true,
    error: null
  })),
  on(loadCompanyUnitsSuccess, (state, { page }) => ({
    ...state,
    loadingCompanyUnits: false,
    companyUnits: page
  })),
  on(loadCompanyUnitsFailure, (state, { error }) => ({
    ...state,
    loadingCompanyUnits: false,
    error
  })),

  // Handle change selected company
  on(changeSelectedCompany, (state, { item }) => ({
    ...state,
    selectedCompany: item,
    selectedCompanyUnit: null,
    companyUnits: null
  })),

  on(changeYear, (state, { year }) => ({
    ...state,
    year
  }))
);

export function localStorageMetaReducer<T>(reducer: ActionReducer<T>): ActionReducer<T> {
  return function (state, action) {
    if (action.type === INIT || action.type === UPDATE) {
      const savedState = localStorage.getItem('esgCompanyContext');
      if (savedState) {
        try {
          return JSON.parse(savedState);
        } catch {
          localStorage.removeItem('esgCompanyContext');
        }
      }
    }

    const nextState = reducer(state, action);

    localStorage.setItem('esgCompanyContext', JSON.stringify(nextState));

    return nextState;
  };
}
