<p-toast position="top-right"></p-toast>
<p-confirmDialog appendTo="body"></p-confirmDialog>


<div class="content-block">
    <router-outlet></router-outlet>
</div>

<footer class="hidden print:block print-footer">
    <div class="flex gap-2 items-center justify-center">
        <img class="h-8 w-8" src="../assets/img/logo_sygnet.svg" alt="Ekowitryna">
        <span>Raport wygenerowany przy użyciu Platformy ESG ekowitryna.pl </span>
    </div>
</footer>